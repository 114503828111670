<template>
  <b-container class="margin-top-60">
    <b-row>
      <b-col>
        <vary-card :title="trans('global.settings')" :description="trans('units.units_fase_prepare_description')" class="margin-bottom-30">
          <b-row>
            <b-col sm="12" md="6">
              <b-form-group id="project_image_group" :label="trans('projects.image')" label-for="project_image">
                <div :class="(!projectImageStyle)? 'noimg' : ''" class="vary-image-container" :style="projectImageStyle">
                  <div v-if="!projectImageStyle" class="text">
                    <i class="fas fa-file-image" style="font-size: 40px;"></i>
                    <span>{{ trans('projects.image_description') }}</span>
                  </div>
                </div>
                <div v-if="uploadStatus === 1" class="vary-image-progress">
                  <b-progress :value="Math.round(progress)" variant="primary" striped :animated="true" show-progress></b-progress>
                </div>
                <b-form-file
                  id="project_image"
                  v-model="settingsForm.image"
                  :state="imageFileStatus"
                  :placeholder="trans('projects.image_placeholder')"
                  class="margin-top-16"
                  accept="image/jpeg, image/png"
                  @change="upload"
                >
                </b-form-file>
              </b-form-group>
            </b-col>
          </b-row>
        </vary-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import VaryCard from '../../Core/Card'
  const fb = require('../../../firebaseConfig')

  export default {
    name: 'ProjectSettings',
    components: {
      'vary-card': VaryCard
    },
    props: {
      project: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        uploadStatus: 0, // 0 = nothing, 1 = progress, 2 = complete
        progress: 0,
        submitted: false,
        loading: false,
        imageFileStatus: null,
        settingsForm: {
          image: null
        }
      }
    },
    computed: {
      projectImageStyle () {
        const lastProjectImageKey = this.project.image !== undefined ? Object.keys(this.project.image).pop() : null
        return (this.project.image !== undefined) ? 'background-image: url("' + process.env.VUE_APP_IMAGE_CDN_URL + '/' + this.project.image[lastProjectImageKey].path + '/' + this.project.image[lastProjectImageKey].file + '")' : null
      }
    },
    mounted () {
      // console.log('Triggering thingy:')
      // let changeImage = fb.func.httpsCallable('prospectImageMonitor')
      //
      // changeImage({test: 'A test message tekst'}).then((res) => {
      //   console.log(res)
      // })

      // console.log(process.env.VUE_APP_NODE_ENV)
    },
    methods: {
      upload (e) {
        const self = this
        this.uploadStatus = 1
        this.settingsForm.image = e.target.files[0]

        let randomTxt = ''
        const length = 16
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

        for (let i = 0; i < length; i++) {
          randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
        }

        if (this.settingsForm.image !== undefined) {
          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: this.settingsForm.image.type
          }

          const newFilename = this.project.slug + '_cover_' + randomTxt + '.' + this.settingsForm.image.name.split('.').pop()
          const fileOrgName = this.settingsForm.image.name
          const imageTargetPath = 'projects/' + this.project.varycode + '/images'

          const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(this.settingsForm.image, metadata) // Upload the file

          uploadTask.on('state_changed', function (snapshot) {
            self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
            }
          }, function (error) {
            switch (error.code) {
              case 'storage/unauthorized':
                console.log('Storage autorization required, access denied...')
                break
              case 'storage/canceled':
                console.log('The upload was cancelled')
                break
              case 'storage/unknown':
                console.log('An unknown error occurred during upload')
                break
            }
          }, function () {
            // Upload files and save them to the filesCollection
            self.uploadStatus = 2

            // We're adding images to a object to keep the history of uploaded images
            const id = self.project.image !== undefined ? parseInt(Object.keys(self.project.image)[Object.keys(self.project.image).length - 1]) + 1 : 0

            fb.projectsCollection.doc(self.project._id).update({
              [`image.${id}`]: {
                createdOn: new Date(),
                file: newFilename,
                orgFile: fileOrgName,
                path: imageTargetPath,
                meta: metadata,
                userId: self.$store.state.auth.userProfile.uid,
                deleted: false
              }
            }).then(() => {
              self.$bvToast.toast(self.trans('projects.image_success'), {
                title: self.trans('global.success'),
                variant: 'success',
                solid: true
              })
            })
          })
        } else {
          this.uploadStatus = 0
          console.log('Imagefile data not found')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .vary-image-container {
    display: flex;
    background-color: #fcfcfc;
    background-size: cover;
    border: 1px solid #DFE4EB;
    border-radius: 4px;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 390px;
    overflow: hidden;

    &.noimg {
      padding-top: 66.67%;
    }

    .text {
      margin-top: -66.67%;
      color: #B5BCC6;
      text-align: center;

      i {
        margin-bottom: 20px;
      }

      span {
        display: block;
      }
    }
  }

  .vary-image-progress {
    transition: opacity .5s;

    margin-top: 16px;
    margin-bottom: -8px;
  }
</style>
