<template>
  <b-container id="project-overview" class="margin-top-60">
    <b-row class="align-content-strech flex-wrap">
      <b-col sm="6">
        <vary-card :title="trans('units.units_fase_prepare')" :description="trans('units.units_fase_prepare_description')" class="margin-bottom-30">
          <div v-if="projectUnits.length === 0" class="d-flex align-content-center flex-column">
            <p> {{ trans('units.no_units_found') }} </p>
          </div>
          <b-list-group v-else class="room-list">
            <b-list-group-item v-for="( unit, index ) in projectUnits" :key="unit.slug" :to="'/projects/' + project.slug + '/planner/' + unit.slug">
              <span>{{ unit.name }}</span><span v-if="unitHasRooms(index)">{{ unitHasRooms(index) }} {{ trans('global.room').toLowerCase() }}</span><span v-else>{{ trans('rooms.no_rooms') }}</span>
            </b-list-group-item>
          </b-list-group>
          <div class="d-flex align-content-center flex-column padding-top-26">
            <b-button v-b-modal.add-unit-modal variant="primary" class="mx-auto my-1 padding-left-26 padding-right-26">
              {{ trans('units.add_unit_' + projectType) }}
            </b-button>
            <b-button :to="'/projects/' + project.slug + '/planner'" variant="primary" class="mx-auto my-1 padding-left-26 padding-right-26">
              {{ trans('projects.go_to_planning') }}
            </b-button>
          </div>
        </vary-card>
      </b-col>
      <b-col sm="6">
        <vary-card :title="trans('units.units_fase_sale')" :description="trans('units.units_fase_sale_description')" class="margin-bottom-30">
          <div v-if="projectProspects.length === 0" class="d-flex align-content-center flex-column">
            <p> {{ trans('prospects.none_found') }} </p>
          </div>
          <b-list-group v-else class="room-list">
            <b-list-group-item v-for="prospect in projectProspects" :key="prospect.slug" :to="'/projects/' + project.slug + '/sales/' + prospect.slug">
              <span>{{ prospect.title }}</span><span>{{ prospect.slug }}</span>
            </b-list-group-item>
          </b-list-group>
          <div class="d-flex align-content-center flex-column padding-top-26">
            <b-button :to="'/projects/' + project.slug + '/sales'" variant="primary" class="mx-auto my-1 padding-left-26 padding-right-26">
              {{ trans('projects.go_to_sales') }}
            </b-button>
          </div>
        </vary-card>
      </b-col>
    </b-row>
    <b-row class="align-content-strech flex-wrap margin-top-26">
      <b-col sm="6">
        <vary-card :title="trans('units.units_fase_building')" :description="trans('units.units_fase_building_description')" class="margin-bottom-30">
          <div style="text-align: center; margin-bottom: 80px;">
            <i class="fas fa-hard-hat margin-top-60" style="font-size: 50px; opacity: 0.2;"></i>
          </div>
        </vary-card>
      </b-col>
      <b-col sm="6">
        <vary-card :title="trans('units.units_fase_followup')" :description="trans('units.units_fase_followup_description')" class="margin-bottom-30">
          <div style="text-align: center; margin-bottom: 80px;">
            <i class="fas fa-people-carry margin-top-60" style="font-size: 50px; opacity: 0.2;"></i>
          </div>
        </vary-card>
      </b-col>
    </b-row>
    <ModalAddUnit :heading="projectType"></ModalAddUnit>
  </b-container>
</template>

<script>
  // const fb = require('../../../firebaseConfig')
  import { mapState } from 'vuex'
  import VaryCard from '../../../Core/Card'
  import ModalAddUnit from '../Modals/AddUnit'

  /*
   Project-types (hardcoded for now):
   1. Hytter
   2. Leiligheter
   3. Hus
   4. Næring
   5. Annet
  */

  export default {
    name: 'ProjectDashboard',
    components: {
      ModalAddUnit,
      'vary-card': VaryCard
    },
    props: {
      project: {
        type: Object,
        required: true
      },
      projectType: {
        type: String,
        required: true
      },
      projectUnits: {
        type: Array,
        required: true
      },
      projectProspects: {
        type: Array,
        required: true
      }
    },
    computed: {
      ...mapState({
        projects: state => state.projects.projects
      })
    },
    methods: {
      unitHasRooms (id) {
        const rooms = this.projectUnits[id].rooms !== undefined && Object.keys(this.projectUnits[id].rooms).length > 0
        return (rooms ? Object.keys(this.projectUnits[id].rooms).length : false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .vary-image-container {
    display: flex;
    background-color: #fcfcfc;
    background-size: cover;
    border: 1px solid #DFE4EB;
    border-radius: 4px;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 390px;
    overflow: hidden;

    &.noimg {
      padding-top: 66.67%;
    }

    .text {
      margin-top: -66.67%;
      color: #B5BCC6;
      text-align: center;

      i {
        margin-bottom: 20px;
      }

      span {
        display: block;
      }
    }
  }

  .vary-image-progress {
    transition: opacity .5s;

    margin-top: 16px;
    margin-bottom: -8px;
  }
</style>
