<template>
  <div id="content-wrapper">
    <TopNavbar :user-profile="userProfile" />
    <div v-if="!project" class="loading">
      {{ trans('global.loading') }}
    </div>
    <div v-else id="content">
      <div id="project-topbar">
        <b-container fluid>
          <b-row>
            <b-col sm="10" class="projectname padding-top-30 padding-bottom-16">
              <h2>{{ project.name }}</h2>
            </b-col>
            <b-col sm="2">
              <div class="d-flex align-items-center h-100 tools">
                <b-button
                  variant="primary"
                  class="ml-auto"
                  @click="toggleTab"
                >
                  <i v-if="activeTab === 'settings'" class="fas fa-angle-left padding-right-6"></i>
                  {{ activeTab === 'dashboard' ? trans('global.settings') : trans('global.back') }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <ProjectDashboard
        v-if="activeTab === 'dashboard'"
        id="project-dashboard"
        :project="project"
        :project-type="projectType"
        :project-units="projectUnits"
        :project-prospects="projectProspects"
      ></ProjectDashboard>
      <ProjectSettings
        v-if="activeTab === 'settings'"
        id="project-settings"
        :project="project"
      ></ProjectSettings>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import TopNavbar from '../../../components/Navigation/TopNavbar'
  import ProjectSettings from '../../../components/Views/Projects/Settings'
  import ProjectDashboard from '../../../components/Views/Projects/Project/Dashboard'

  export default {
    name: 'ProjectPage',
    components: {
      TopNavbar,
      ProjectDashboard,
      ProjectSettings
    },
    data () {
      return {
        loading: false,
        projectTypes: {
          1: 'cabin',
          2: 'apartment',
          3: 'house',
          4: 'industry',
          5: 'unit'
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile,
        projects: state => state.projects.projects,
        configs: state => state.configs,
        units: state => state.units.units,
        prospects: state => state.prospects.prospects
      }),
      project () {
        return this.projects.find(project => project.slug === this.$route.params.projectslug)
      },
      activeTab () {
        return (this.configs.projectTopbarBtn !== undefined && this.configs.projectTopbarBtn.activeTab !== '') ? this.configs.projectTopbarBtn.activeTab : 'dashboard'
      },
      projectType () {
        return this.project.type !== undefined ? this.projectTypes[this.project.type] : 1
      },
      projectUnits () {
        return this.units.filter(unit => unit.projectId === this.project._id)
      },
      projectProspects () {
        return this.prospects.filter(prospect => prospect.projectId === this.project._id)
      }
    },
    beforeMount () {
      this.$store.commit('configs/setProjectTopbarBtn', 'dashboard')
      this.updateBreadcrumb(
        [
          { text: this.trans('global.projects'), to: '/projects' },
          {
            text:
              {
                store: 'projects',
                searchin: 'slug',
                searchfor: this.$route.params.projectslug
              },
            to: '/projects/' + this.$route.params.projectslug
          }
        ]
      )
    },
    mounted () {
      if (this.units === undefined) this.$store.dispatch('units/getUnits')
    },
    methods: {
      toggleTab () {
        this.$store.commit('configs/setProjectTopbarBtn', this.activeTab === 'dashboard' ? 'settings' : 'dashboard')
      }
    }
  }
</script>
